<template>
  <div v-loading="isLoading" class="fluent">
    <div class="header">
      <div class="header-btn">
        <PromotedMode :active="promotedMode" @change="changePromotedMode" />
        <el-button
          @click="runHandle"
        ><svg-icon icon-class="run" />运行</el-button>
      </div>
      <div v-if="promotedMode ==='interaction-body'" class="content">
        <application-public :iscpu="1" :dirs="choiceDirData" :valid="isValid" @validChange="validChange" @changDirs="changDirs" @getData="getData" @formChange="formChange" />
        <div class="right">
          <div class="desc">
            <div class="title">应用介绍</div>
            <div class="text">A fluent is a time-varying quantity or variable.The term was used by Isaac Newton in his early calculus to describe his form of a function.The concept was introduced by Newton in 1665 and detailed in his mathematical treatise, Method of Fluxions.Newton described any variable that changed its value as a fluent – for example, the velocity of a ball thrown in the air. The derivative of a fluent is known as a fluxion, the main focus of Newton's calculus. A fluent can be found from its corresponding fluxion through integration.</div>
          </div>
          <div class="app-info">
            <div class="title">应用参数</div>
            <div>
              <v-form-render ref="fluentRef" :form-json="formJson" :form-data="formData" :option-data="optionData" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="content">
        <application-public :iscpu="1" :dirs="choiceDirData" :valid="isValid" @validChange="validChange" @changDirs="changDirs" @getData="getData" @formChange="formChange" />
        <div class="right">
          <div class="desc">
            <div class="title">应用介绍</div>
            <div class="text">A fluent is a time-varying quantity or variable.The term was used by Isaac Newton in his early calculus to describe his form of a function.The concept was introduced by Newton in 1665 and detailed in his mathematical treatise, Method of Fluxions.Newton described any variable that changed its value as a fluent – for example, the velocity of a ball thrown in the air. The derivative of a fluent is known as a fluxion, the main focus of Newton's calculus. A fluent can be found from its corresponding fluxion through integration.</div>
          </div>
          <div class="app-info">
            <div class="title">应用参数</div>
            <div>
              <v-form-render ref="fluentRef" :form-json="onlyHaveVersion" :form-data="formData" :option-data="optionData" />
            </div>
          </div>
          <WritExecution :script="script" @getCommand="getCommand" />
        </div>
      </div>
    </div>
    <!-- 选择目录组件 -->
    <choice-file :visible="dirFormVisible" :type="choiceType" @confirmDirs="confirmDirs" />
  </div>

</template>

<script>
import { ElMessage } from 'element-plus'
import store from '@/store'
import { queryCustomParam } from '@/api/ehpc'
import {
  addJobs
} from '@/api/file'
import choiceFile from '../components/ChoiceFile.vue'
import applicationPublic from '../components/ApplicationPublic.vue'
import WritExecution from '../components/WritExecution.vue'
import PromotedMode from '../components/PromotedMode.vue'

export default {
  components: { choiceFile, applicationPublic, WritExecution, PromotedMode },
  data() {
    const validateName = (rule, value, callback) => {
      const regExp = /^[a-z\d][a-z\d\-_]*[a-z\d]$/
      if (value === '') {
        callback(new Error('请输入作业名称'))
      } else {
        if (value.length < 4 || value.length > 15) {
          callback(new Error('作业名称应为4-15个字符'))
        } else {
          if (regExp.test(value) === false) {
            callback(new Error('作业名称仅可包含小写字母数字破折号(-)和下划线(_)且只能以字母数字作为开头结尾'))
          } else {
            callback()
          }
        }
      }
    }

    return {
      valid: 0,
      isValid: 0,
      choiceDirData: '',
      isLoading: true,
      currentRow: -1,
      loading: false,
      loadingDialog: false,
      dirFormVisible: false,
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      addForm: {
        jobName: '',
        dir: '',
        cpu: 1,
        gpu: 0
      },
      choiceType: 'dir',
      appForm: {
        file: ''
      },
      appRules: {
        file: [{ required: true, trigger: 'change', message: '请选择文件' }],
        version: [{ required: true, trigger: 'change', message: '请选择版本' }]
      },
      rules: {
        jobName: [{ required: true, trigger: 'blur', validator: validateName }],
        dir: [{ required: true, trigger: 'change', message: '请选择目录' }],
        cpu: [{ required: true, trigger: 'change', message: '请选择CPU' }],
        gpu: [{ required: true, trigger: 'change', message: '请选择GPU' }]
      },
      formJson: {},
      formData: {},
      optionData: {},
      file: {},
      version: {},
      focus: {},
      jsonData: {},
      jsonNameList: [],
      isHasVersion: false,
      nodes: [],
      script: '',
      promotedMode: 'interaction-body',
      onlyHaveVersion: {},
      allJsonData: {}
    }
  },
  mounted() {
    this.getCustom()
    this.$nextTick(() => {
      this.$refs.fluentRef.addEC('Fluent', this)
    })
  },
  methods: {
    changePromotedMode(val) {
      this.promotedMode = val
      this.addForm = {
        jobName: '',
        dir: '',
        cpu: 1,
        gpu: 0
      }
      this.choiceDirData = ''
      this.queue = ''
      this.valid = 0
      this.isValid = 0
      this.script = 'fluent 3ddp -g -slurm -t $SLURM_NTASKS -pib.ibv -cnf=slurmhosts.$SLURM_JOB_ID.txt -mpi=intel -ssh -i pipe.jou'

      this.$nextTick(() => {
        this.$refs.fluentRef.addEC('Fluent', this)
      })
    },
    getCommand(val) {
      this.script = val
    },
    // 左侧子组件传值
    getData(isLoading, queue) {
      // this.isLoading = isLoading
      this.queue = queue
    },
    formChange(form) {
      this.addForm = form
    },
    validChange(val) {
      this.valid = val
    },
    // 选择目录 改变
    changDirs() {
      this.choiceType = 'dir'
      this.dirFormVisible = true
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    mousePass(row, column, cell, event) {
      this.currentRow = row.index
    },
    clickDir() {
      this.dirFormVisible = false
    },
    // 生成五位随机数
    randomString(e) {
      var t = 'abcdefghijklmnopqrstuvwxyz0123456789'
      var a = t.length
      var n = ''
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },
    runHandle() {
      this.nodes = []
      this.nodes.push(this.addForm.nodes)
      this.nodes.push(this.addForm.nodes)
      var script = ''
      if (this.promotedMode === 'interaction-body') {
        this.jsonNameList.map(item => {
          if (item == 'fluent ') {
            script += item + this.$refs.fluentRef.getWidgetRef(item).getValue()
          } else {
            script += item + '"' + this.$refs.fluentRef.getWidgetRef(item).getValue() + '"'
          }
        })
      } else {
        script = this.script
      }
      // const script = 'fluent 3ddp -g -slurm -t $SLURM_NTASKS -pib.ibv -cnf=slurmhosts.$SLURM_JOB_ID.txt -mpi=intel -ssh -i ' + this.file.getValue()
      this.isValid = 1
      var data
      if (this.isHasVersion) {
        data = {
          'app': 'fluent',
          'coupon_id': '',
          'script': script,
          'tasks': this.addForm.tasks_per_node * this.addForm.nodes,
          'tasks_per_node': this.addForm.tasks_per_node,
          'nodes': this.nodes,
          'partition': this.queue.queueName,
          'gpus': this.addForm.gpu,
          'jobType': 3,
          'name': this.addForm.jobName + this.randomString(5),
          'version': this.$refs.fluentRef.getWidgetRef('version').getValue(),
          'current_working_directory': this.addForm.dir,
          'time_limit': this.addForm.lateTime === null ? null : parseInt(this.addForm.lateTime),
          'exclusive': this.addForm.exclusive ? 'user' : null

        }
      } else {
        data = {
          'app': 'fluent',
          'coupon_id': '',
          'script': script,
          'tasks': this.addForm.tasks_per_node * this.addForm.nodes,
          'tasks_per_node': this.addForm.tasks_per_node,
          'nodes': this.nodes,
          'partition': this.queue.queueName,
          'gpus': this.addForm.gpu,
          'jobType': 3,
          'name': this.addForm.jobName + this.randomString(5),
          'version': 2020,
          'current_working_directory': this.addForm.dir,
          'time_limit': this.addForm.lateTime === null ? null : parseInt(this.addForm.lateTime),
          'exclusive': this.addForm.exclusive ? 'user' : null

        }
      }
      if (this.addForm.jobName !== '' && this.addForm.dir !== '' && this.valid === 1) {
        this.$refs.fluentRef.getFormData().then(response => {
          if (this.queue !== '') {
            addJobs(data).then((response) => {
              if (response.meta.status === 200) {
                ElMessage.success('运行成功')
                const idObj = {
                  id: 3296931928216553,
                  linkId: 1661833336145951
                }
                this.$store.dispatch('core/close', idObj)
                this.$store.dispatch('core/openApp', 3145878294710521)
                this.row = ''
                this.queue = ''
                this.isValid = 3
              } else if (response.meta.status === 5005) {
                ElMessage.error('余额不足！')
              } else {
                ElMessage.error(response.meta.msg)
              }
            })
          } else {
            ElMessage.error('未选择队列信息，请点击选中队列信息！')
          }
        })
      }
    },
    getCustom() {
      queryCustomParam(1249368597617151).then((response) => {
        const Base64 = require('js-base64').Base64
        const Json = Base64.decode(response.data)
        this.jsonData = JSON.parse(Json)
        this.allJsonData = JSON.parse(Json)
        const onlyHaveVersion = this.jsonData.widgetList.map(item => {
          if (item.options.name !== 'version') {
            item.options.hidden = true
          }
          return item
        })
        this.onlyHaveVersion = JSON.parse(JSON.stringify({ ...this.jsonData, widgetList: onlyHaveVersion }))

        if (response.meta.status === 200) {
          this.$refs.fluentRef.setFormJson(Json)
          this.$nextTick(() => {
            // this.file = this.$refs.fluentRef.getWidgetRef('file')
            // this.version = this.$refs.fluentRef.getWidgetRef('version')
            this.focus = this.$refs.fluentRef.getWidgetRef('focus')
            this.jsonData.widgetList.forEach(e => {
              if (e.options.name !== 'version' && e.options.name !== 'focus') {
                this.jsonNameList.push(e.options.name)
              }
            })
            this.isHasVersion = this.jsonData.widgetList.some(item => item.options.name === 'version')
            this.isLoading = false
          })
        }
      })
    },
    // 选择文件
    changFiles() {
      this.choiceType = 'file'
      this.dirFormVisible = true
    },
    // 确定选择目录
    confirmDirs(data, dirFormVisible, type) {
      this.dirFormVisible = dirFormVisible
      if (type === 'dir') {
        this.addForm.dir = data
        this.choiceDirData = data
      } else if (type === 'file') {
        this.$refs.fluentRef.getWidgetRef('focus').focus()
        if (data) {
          // this.file.setValue(data)
          this.$refs.fluentRef.getWidgetRef(this.jsonNameList[1]).setValue(`${data}`)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fluent {
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .header {
    text-align: right;
    margin-bottom: 15px;
    ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
    .header-btn{
      display: flex;
      justify-content: space-between;
    }
  }
  .content {
    text-align: left;
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      margin-top: 20px;
      padding: 30px 0;
      background: rgba(245, 247, 250, 0.5);
      border: 1px solid #E8E8E8;
      min-width: 320px;
      width: 45%;
      position: relative;
      height: 100%;
      .el-form-item--small.el-form-item{
        margin-bottom: 40px;
      }
      .box{
        padding: 0 20px;
      }
      .middle{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .border-style{
          border-bottom: 1px solid #D8D8D8;
          width: 100px;
        }
      }
      .title {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
    }
      ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
    }
    .right {
      margin-top: 20px;
      padding: 0 10px;
      width: 100%;
      height: 100%;
      margin-left: 10px;
      .text{
        text-indent: 2em;
      }
       .desc{
         margin-bottom: 30px;
       }
      .desc,
      .app-info{
        position: relative;
        border: 1px solid #E8E8E8;
        padding: 30px 20px;
        background: rgba(245, 247, 250, 0.5);
      }
      .title{
        position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
      }
    }
  }
  ::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
}
</style>
<style>
/* .el-dialog{
  border-radius: 20px;
}
.el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .el-dialog__body{
      padding: 0;
    } */
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
